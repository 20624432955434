@font-face {
  font-family: "Montserrat";
  src: url("https://cdn.avt.pl/fonts/MontserratRegular.eot");
  src: url("https://cdn.avt.pl/fonts/MontserratRegular.eot") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/MontserratRegular.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/MontserratRegular.woff") format("woff"), url("https://cdn.avt.pl/fonts/MontserratRegular.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/MontserratRegular.svg#MontserratRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("https://cdn.avt.pl/fonts/MontserratMedium.eot");
  src: url("https://cdn.avt.pl/fonts/MontserratMedium.eot") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/MontserratMedium.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/MontserratMedium.woff") format("woff"), url("https://cdn.avt.pl/fonts/MontserratMedium.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/MontserratMedium.svg#MontserratRegular") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("https://cdn.avt.pl/fonts/MontserratSemiBold.eot");
  src: url("https://cdn.avt.pl/fonts/MontserratSemiBold.eot") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/MontserratSemiBold.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/MontserratSemiBold.woff") format("woff"), url("https://cdn.avt.pl/fonts/MontserratSemiBold.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/MontserratSemiBold.svg#MontserratBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("https://cdn.avt.pl/fonts/MontserratBold.eot");
  src: url("https://cdn.avt.pl/fonts/MontserratBold.eot") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/MontserratBold.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/MontserratBold.woff") format("woff"), url("https://cdn.avt.pl/fonts/MontserratBold.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/MontserratBold.svg#MontserratBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("https://cdn.avt.pl/fonts/MontserratBlack.eot");
  src: url("https://cdn.avt.pl/fonts/MontserratBlack.eot") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/MontserratBlack.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/MontserratBlack.woff") format("woff"), url("https://cdn.avt.pl/fonts/MontserratBlack.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/MontserratBlack.svg#MontserratBlack") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FontAwesome";
  src: url("https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.fa, [class^=fa-link] {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-link-facebook::before {
  content: "\f082";
}
.fa-link-youtube::before {
  content: "\f167";
}
.fa-link-linkedin::before {
  content: "\f08c";
}
.fa-link-twitter::before {
  content: "\f081";
}
.fa-link-twitter::before {
  content: "\f081";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-search:before {
  content: "\f002";
}

.fa-clock:before {
  content: "\f017";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-user:before {
  content: "\f007";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-file-word-o:before {
  content: "\f1c2";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-link-google-plus:before {
  content: "\f0d4";
}

.fa-arrow-up:before {
  content: "\f062";
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}

.alert-message .close {
  display: none;
}
.alert-message .alert-heading {
  display: none;
}

.container-fluid.w, .w#system-message-container {
  max-width: 1200px;
}

.bg-white {
  background-color: rgb(255, 255, 255);
}

.font-bold {
  font-weight: bold;
}

#system-message-container {
  max-width: 1200px;
  margin: 0 auto;
}

a, a:link, a:hover {
  color: #333;
  text-decoration: none;
}

.hidden-xl-down {
  overflow: hidden;
}

aside section:first-child .section-header {
  margin-top: 0 !important;
}

video {
  max-width: 100%;
}

.img-aligner {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.float {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9001;
}

.position-relative {
  position: relative !important;
}

pre.language-php {
  white-space: normal;
}

.bg-main {
  background-color: rgb(255, 255, 255);
}

.ad-img-responsive img {
  max-width: 100%;
  height: auto;
}

.h-100 {
  height: 100%;
}

.p-sticky {
  top: 15px;
  position: sticky;
  transition: top 0.2s ease-in-out;
}

.menu-down .p-sticky {
  top: 80px;
}

.seo-article {
  background-color: #CEE1F0;
  position: relative;
  padding-bottom: 75px;
}
.seo-article:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff;
  display: block;
  z-index: 1;
}
.seo-article__wrapper {
  background-color: rgb(255, 255, 255);
  position: relative;
  z-index: 5;
  box-shadow: 10px 15px 46px rgba(0, 0, 0, 0.1607843137);
  padding: 30px 15px 45px;
}
@media (min-width: 768px) {
  .seo-article__wrapper {
    padding: 30px 30px 60px;
  }
}
@media (min-width: 1025px) {
  .seo-article__wrapper {
    padding: 45px 45px 75px;
  }
}
@media (min-width: 1200px) {
  .seo-article__wrapper {
    padding: 60px 60px 90px;
  }
}
.seo-article__logo {
  max-width: 240px;
  margin: 0 auto 10px;
}
@media (min-width: 576px) {
  .seo-article__logo {
    margin: 0 0 10px;
  }
}
.seo-article__logo img {
  display: block;
  max-width: 100%;
  height: auto;
}
.seo-article__title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 50px;
  text-align: center;
}
@media (min-width: 576px) {
  .seo-article__title {
    text-align: left;
  }
}
.seo-article__introtext {
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
}
.seo-article__introtext a {
  text-decoration: none;
  color: #0967B0;
}

body, html {
  scroll-behavior: smooth;
}

body.search-open {
  position: fixed;
  overflow: hidden;
}

#mobile-search-sticky {
  position: absolute;
  font-size: 24px;
  color: #989898;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: none;
}

.fixed #mobile-search-sticky {
  display: flex;
}

header .socials {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  margin: 5px 0 30px;
}
@media (min-width: 440px) {
  header .socials {
    width: auto;
    justify-content: flex-start;
    margin: 0;
  }
}
header .socials a {
  margin-right: 15px;
}
header .socials a:last-child {
  margin-right: 0;
}

.mobile-search {
  opacity: 0;
  pointer-events: none;
  transform: translate3d(-100%, 0, 0);
  position: fixed;
  background-image: url("/templates/main/images/search-bg.png");
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background-position: center;
}
@media (min-width: 768px) {
  .mobile-search {
    background-size: cover;
  }
}
.mobile-search.open {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  pointer-events: all;
}
.mobile-search:after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #0967b0;
  opacity: 0.86;
}
.mobile-search__socials {
  position: absolute;
  z-index: 3;
  top: 15px;
  left: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.mobile-search__socials a {
  margin-right: 25px;
  font-size: 30px;
  color: #ffffff;
  display: block;
  height: 30px;
  width: 30px;
  position: relative;
}
.mobile-search__socials a:last-child {
  margin-right: 0;
}
.mobile-search__socials a i {
  position: absolute;
  top: 0;
  left: 0;
}
.mobile-search__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  z-index: 3;
  cursor: pointer;
}
.mobile-search__close:before, .mobile-search__close:after {
  content: "";
  height: 4px;
  width: 100%;
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(45deg);
}
.mobile-search__close:after {
  transform: rotate(-45deg);
}
.mobile-search__form {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 15px;
  height: 64px;
  margin: auto;
  max-width: 700px;
  box-shadow: 0 23px 18px rgba(0, 0, 0, 0.26);
}
.mobile-search__form input {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 70px;
  font-size: 14px;
  border: none;
  outline: none;
}
.mobile-search__form input::placeholder {
  opacity: 1;
}
.mobile-search__form button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 64px;
  background: none;
  border: none;
  box-shadow: none;
  color: #999999;
  font-size: 24px;
  cursor: pointer;
}

#return-top {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  width: 60px;
  height: 60px;
  background-color: #0967b0;
  border: 3px solid #fff;
  border-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 23px;
  transition: opacity 0.2s ease-in-out;
  z-index: 99999;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}
@media (min-width: 501px) {
  #return-top {
    bottom: 25px;
    right: 25px;
  }
}
#return-top.show {
  opacity: 1;
  pointer-events: all;
}

.photovoltaics {
  background-color: #161F3A;
  background-image: url("/templates/main/images/svg/background-grid.png");
  background-repeat: no-repeat;
  display: block;
  color: rgb(255, 255, 255);
  margin: 0 auto 30px;
}
.photovoltaics__frame {
  background-color: #161F3A;
  color: rgb(255, 255, 255);
  margin-bottom: 35px;
  padding: 20px 20px 30px;
}
@media (min-width: 576px) {
  .photovoltaics__frame {
    padding: 30px 30px 40px;
  }
}
.photovoltaics__frame-header {
  padding-bottom: 19px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #E2CA2C;
}
.photovoltaics__frame-header-icon {
  background-color: #454C61;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  margin-right: 15px;
}
.photovoltaics__frame-header-icon img {
  display: block;
  max-width: 100%;
  height: auto;
}
.photovoltaics__frame-header-text {
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-size: 23px;
  max-width: calc(100% - 60px);
}
@media (min-width: 576px) {
  .photovoltaics__frame-header-text {
    font-size: 27px;
  }
}
.photovoltaics__frame-text {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 576px) {
  .photovoltaics__frame-text {
    font-size: 18px;
    line-height: 30px;
  }
}
.photovoltaics__header {
  margin-bottom: 35px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  align-content: center;
}
.photovoltaics__header-icon {
  background-color: #161F3A;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  margin-right: 15px;
}
.photovoltaics__header-icon img {
  display: block;
  max-width: 100%;
  height: auto;
}
.photovoltaics__header-text {
  font-size: 27px;
  font-weight: 700;
  max-width: calc(100% - 60px);
}
.photovoltaics__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 30px;
  max-width: 770px;
}
.photovoltaics__wrap .photovoltaics-left--sm {
  width: 100%;
  display: block;
}
@media (min-width: 1025px) {
  .photovoltaics__wrap .photovoltaics-left--sm {
    display: none;
  }
}
.photovoltaics__wrap .photovoltaics-right--md {
  width: 100%;
  display: none;
}
@media (min-width: 1025px) {
  .photovoltaics__wrap .photovoltaics-right--md {
    display: block;
  }
}
.photovoltaics__wrap .photovoltaics {
  order: 1;
  margin: 0;
}
@media (min-width: 1025px) {
  .photovoltaics__wrap .photovoltaics {
    order: 2;
  }
}
.photovoltaics__wrap .photovoltaics__articles {
  order: 2;
  display: flex;
  flex-wrap: wrap;
  background-color: #161F3A;
  border-top: 1px solid #3A4259;
  padding: 35px 0 40px;
}
@media (min-width: 576px) {
  .photovoltaics__wrap .photovoltaics__articles {
    padding: 35px 20px 40px;
  }
}
@media (min-width: 1025px) {
  .photovoltaics__wrap .photovoltaics__articles {
    border-bottom: 1px solid #3A4259;
    padding: 35px 35px 40px;
    order: 1;
  }
}
.photovoltaics__wrap .photovoltaics__article {
  padding: 0 15px;
  width: 100%;
}
@media (min-width: 576px) {
  .photovoltaics__wrap .photovoltaics__article {
    width: 50%;
  }
}
@media (min-width: 1025px) {
  .photovoltaics__wrap .photovoltaics__article {
    padding: 0;
    width: 100%;
  }
}
.photovoltaics__article {
  margin-bottom: 30px;
}
.photovoltaics__article:last-child {
  margin-bottom: 0;
}
.photovoltaics__article-image {
  margin-bottom: 16px;
}
.photovoltaics__article-title {
  font-size: 18px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  letter-spacing: -0.021em;
  line-height: 21px;
}
.photovoltaics:hover {
  color: rgb(255, 255, 255);
}
.photovoltaics-wide {
  max-width: 1170px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.photovoltaics-wide--sm {
  padding: 17px 45px;
  background-position: 280px 20px;
  margin: 15px auto 0;
}
@media (min-width: 820px) {
  .photovoltaics-wide--sm {
    background-position: 640px 20px;
  }
}
.photovoltaics-wide--sm .photovoltaics__text {
  margin-left: 60px;
}
.photovoltaics-wide--md {
  padding: 30px 40px;
  background-position: 999999px 999999px;
  position: relative;
}
@media (min-width: 820px) {
  .photovoltaics-wide--md {
    background-position: 640px 48px;
  }
}
.photovoltaics-wide--md .photovoltaics__text {
  margin-left: 90px;
}
.photovoltaics-wide .photovoltaics__text {
  display: none;
}
@media (min-width: 820px) {
  .photovoltaics-wide .photovoltaics__text {
    display: block;
  }
}
.photovoltaics-left {
  max-width: 770px;
}
.photovoltaics-left--sm {
  background-position: 300px 11px;
  padding: 17px 30px;
}
@media (min-width: 576px) {
  .photovoltaics-left--sm {
    padding: 17px 45px;
  }
}
.photovoltaics-left--md {
  padding: 20px 30px 36px;
  background-position: 999999px 999999px;
}
@media (min-width: 440px) {
  .photovoltaics-left--md {
    background-position: 180px 41px;
  }
}
@media (min-width: 576px) {
  .photovoltaics-left--md {
    background-position: 300px 41px;
    padding: 20px 30px 36px 61px;
  }
}
.photovoltaics-left--md .photovoltaics__logo {
  margin-bottom: 11px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 440px) {
  .photovoltaics-left--md .photovoltaics__logo {
    margin-left: 0;
    margin-right: 0;
  }
}
.photovoltaics-left--md .photovoltaics__title {
  text-align: center;
}
@media (min-width: 440px) {
  .photovoltaics-left--md .photovoltaics__title {
    padding-left: 40px;
    text-align: left;
  }
}
.photovoltaics-left--md .photovoltaics__title img {
  margin-left: 13px;
  display: none;
}
@media (min-width: 576px) {
  .photovoltaics-left--md .photovoltaics__title img {
    display: inline-block;
  }
}
.photovoltaics-left--lg {
  padding: 20px 30px 36px;
  background-position: 999999px 999999px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.photovoltaics-left--lg br {
  display: none;
}
@media (min-width: 370px) {
  .photovoltaics-left--lg br {
    display: inline;
  }
}
@media (min-width: 440px) {
  .photovoltaics-left--lg {
    display: block;
    background-position: 290px -65px;
  }
}
@media (min-width: 576px) {
  .photovoltaics-left--lg {
    background-position: 430px -65px;
    padding: 50px 30px 60px 53px;
  }
}
.photovoltaics-left--lg .photovoltaics__logo {
  margin-bottom: 24px;
}
@media (min-width: 440px) {
  .photovoltaics-left--lg .photovoltaics__title,
.photovoltaics-left--lg .photovoltaics__text {
    padding-left: 40px;
  }
}
.photovoltaics-left--lg .photovoltaics__title {
  text-align: center;
}
@media (min-width: 440px) {
  .photovoltaics-left--lg .photovoltaics__title {
    text-align: left;
  }
}
.photovoltaics-left--lg .photovoltaics__title img {
  margin-left: 13px;
  display: none;
}
@media (min-width: 576px) {
  .photovoltaics-left--lg .photovoltaics__title img {
    display: inline-block;
  }
}
.photovoltaics-left--lg .photovoltaics__text {
  margin-bottom: 50px;
}
.photovoltaics-right {
  max-width: 370px;
}
.photovoltaics-right br {
  display: none;
}
@media (min-width: 370px) {
  .photovoltaics-right br {
    display: inline;
  }
}
.photovoltaics-right--sm {
  background-position: 215px -250px;
  padding: 20px 20px 30px;
}
.photovoltaics-right--md {
  padding: 40px 20px 73px;
  background-position: 205px;
}
.photovoltaics-right--md .photovoltaics__logo {
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 440px) {
  .photovoltaics-right--md .photovoltaics__logo {
    margin-left: 0;
    margin-right: 0;
  }
}
.photovoltaics-right--md .photovoltaics__title {
  text-align: center;
}
@media (min-width: 440px) {
  .photovoltaics-right--md .photovoltaics__title {
    padding-left: 40px;
    text-align: left;
  }
}
.photovoltaics-right--lg {
  padding: 40px 30px 64px;
  background-position: 177px -75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 440px) {
  .photovoltaics-right--lg {
    display: block;
  }
}
.photovoltaics-right--lg .photovoltaics__logo {
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 440px) {
  .photovoltaics-right--lg .photovoltaics__logo {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 440px) {
  .photovoltaics-right--lg .photovoltaics__title,
.photovoltaics-right--lg .photovoltaics__text {
    padding-left: 30px;
  }
}
.photovoltaics-right--lg .photovoltaics__text {
  margin-bottom: 50px;
}
.photovoltaics__logo {
  display: block;
  max-width: 100%;
  height: auto;
}
.photovoltaics__title {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}
.photovoltaics__text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: rgb(255, 255, 255);
}
.photovoltaics-swiper {
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.photovoltaics-swiper.swiper-container {
  margin-top: 30px;
}
.photovoltaics-swiper__next, .photovoltaics-swiper__prev {
  position: absolute;
  top: auto;
  bottom: 30px;
  right: 0;
  left: 0;
  padding: 0 15px;
  height: 45px;
  width: 75px;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
}
.photovoltaics-swiper__next img, .photovoltaics-swiper__prev img {
  position: relative;
  z-index: 9;
}
.photovoltaics-swiper__prev {
  right: auto;
  justify-content: flex-start;
  background: -moz-linear-gradient(90deg, rgb(22, 31, 58) 0%, rgba(22, 31, 58, 0) 100%);
  background: -webkit-linear-gradient(90deg, rgb(22, 31, 58) 0%, rgba(22, 31, 58, 0) 100%);
  background: -o-linear-gradient(90deg, rgb(22, 31, 58) 0%, rgba(22, 31, 58, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#161F3A", endColorstr="#161F3A", GradientType=0 );
  background: -ms-linear-gradient(90deg, rgb(22, 31, 58) 0%, rgba(22, 31, 58, 0) 100%);
  background: linear-gradient(90deg, rgb(22, 31, 58) 0%, rgba(22, 31, 58, 0) 100%);
}
.photovoltaics-swiper__prev img {
  transform: rotate(180deg);
}
.photovoltaics-swiper__next {
  left: auto;
  justify-content: flex-end;
}
.photovoltaics-swiper__next:before {
  content: "";
  top: 0;
  right: 0;
  width: 25px;
  height: 45px;
  background-color: #161F3A;
  position: absolute;
}
.photovoltaics-swiper__next:after {
  content: "";
  top: 0;
  right: 25px;
  width: 50px;
  height: 45px;
  position: absolute;
  background: -moz-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgb(22, 31, 58) 100%);
  background: -webkit-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgb(22, 31, 58) 100%);
  background: -o-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgb(22, 31, 58) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#161F3A", endColorstr="#161F3A", GradientType=0 );
  background: -ms-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgb(22, 31, 58) 100%);
  background: linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgb(22, 31, 58) 100%);
}
.photovoltaics__menu-item {
  display: inline-flex;
  padding: 0 20px;
  min-height: 45px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: auto;
  border: 2px solid #454C61;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  white-space: nowrap;
  color: rgb(255, 255, 255);
}
.photovoltaics__menu-item, .photovoltaics__menu-item:link {
  color: rgb(255, 255, 255);
}
.photovoltaics__menu-item--active {
  background-color: rgb(255, 255, 255);
  color: #161F3A;
  border-color: rgb(255, 255, 255);
}
.photovoltaics__menu-item--active:link, .photovoltaics__menu-item--active:visited {
  color: #161F3A;
}
.photovoltaics__menu-item:hover {
  color: rgb(255, 255, 255);
  background-color: #454C61;
  border-color: rgb(255, 255, 255);
}

.active .photovoltaics-swiper, .active .photovoltaics-swiper__next, .active .photovoltaics-swiper__prev {
  opacity: 1;
}
.active .photovoltaics-swiper.swiper-button-disabled, .active .photovoltaics-swiper__next.swiper-button-disabled, .active .photovoltaics-swiper__prev.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

::selection {
  background: rgba(2, 85, 146, 0.75);
}

.error-header {
  height: 100px;
  padding: 32px 15px;
  background-color: #fff;
}
.error-header .logo {
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  transform: translateY(-50%);
}
.error-header .logo-box {
  position: relative;
  display: block;
  height: 100%;
  max-width: 309px;
  margin: 0 auto;
}
.error-main {
  width: 100%;
  height: calc(100vh - 100px);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/background-error.jpg");
  background-repeat: repeat-y, no-repeat;
  background-size: cover, cover;
  background-position: center, center top;
  overflow-x: hidden;
  overflow-y: auto;
}
.error-main .content-wrapper {
  margin: 0 auto;
  padding: 8vh 15px;
  color: #fff;
  text-align: center;
}
@media (min-width: 576px) {
  .error-main .content-wrapper {
    padding: 10vh 15px;
  }
}
@media (min-width: 1366px) {
  .error-main .content-wrapper {
    padding: 12vh 15px;
  }
}
.error-main .content-wrapper a {
  color: #fff;
  font-weight: 700;
  transition: color 0.25s ease-in;
}
.error-main .content-wrapper a:hover {
  color: #0390f7;
}
.error-message, .debug-error, .error-hint, .search-input {
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 576px) {
  .error-message, .debug-error, .error-hint, .search-input {
    font-size: 25px;
  }
}
@media (min-width: 1366px) {
  .error-message, .debug-error, .error-hint, .search-input {
    font-size: 30px;
  }
}
.error-hint, .search-input {
  margin-bottom: 30px;
}
.error-code {
  font-size: 100px;
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 576px) {
  .error-code {
    font-size: 200px;
  }
}
@media (min-width: 1200px) {
  .error-code {
    font-size: 210px;
  }
}
@media (min-width: 1366px) {
  .error-code {
    font-size: 240px;
  }
}

.search-input, .search-button {
  display: block;
  margin: 0 auto;
  color: #fff;
  border: none;
  background: none;
}
.search-input {
  margin-bottom: 20px;
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  transition: border-bottom-color 0.25s ease-in;
}
@media (min-width: 576px) {
  .search-input {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .search-input {
    margin-bottom: 30px;
  }
}
@media (min-width: 1025px) {
  .search-input {
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .search-input {
    margin-bottom: 30px;
  }
}
.search-input:focus {
  border-bottom-color: white;
}
.search-button {
  padding: 12px 40px;
  background-color: #025592;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.25s ease-in;
}
@media (min-width: 576px) {
  .search-button {
    padding: 14px 50px;
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .search-button {
    padding: 14px 50px;
    font-size: 16px;
  }
}
@media (min-width: 1025px) {
  .search-button {
    padding: 14px 50px;
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .search-button {
    padding: 14px 50px;
    font-size: 16px;
  }
}
@media (min-width: 1366px) {
  .search-button {
    padding: 18px 60px;
    font-size: 20px;
  }
}
.search-button:hover, .search-button:focus {
  cursor: pointer;
  background-color: #0390f7;
}

.debug {
  margin-top: 30px;
  text-align: left;
  overflow-x: auto;
}
.debug .table {
  margin: 0 auto;
}
.debug .table td {
  padding: 2px 5px;
}
.debug .table tr:hover {
  background: rgba(255, 255, 255, 0.25);
}
.debug-error {
  text-align: center;
}
.debug-error-code {
  font-weight: 700;
}