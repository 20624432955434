.photovoltaics {
    background-color: #161F3A;
    background-image: url("/templates/main/images/svg/background-grid.png");
    background-repeat: no-repeat;
    display: block;
    color: $color-white;
    margin: 0 auto 30px;
    &__frame {
        background-color: #161F3A;
        color: $color-white;
        margin-bottom: 35px;
        padding: 20px 20px 30px;
        @media(min-width: 576px) {
            padding: 30px 30px 40px;
        }
        &-header {
            padding-bottom: 19px;
            margin-bottom: 24px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            border-bottom: 1px solid #E2CA2C;
            &-icon {
                background-color: #454C61;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                align-items: center;
                text-align: center;
                margin-right: 15px;
                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                }
            }
            &-text {
                font-weight: 700;
                color: $color-white;
                font-size: 23px;
                max-width: calc(100% - 60px);
                @media(min-width: 576px) {
                    font-size: 27px;
                }
            }
        }
        &-text {
            font-size: 16px;
            line-height: 24px;
            @media(min-width: 576px) {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
    &__header {
        margin-bottom: 35px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        align-content: center;
        &-icon {
            background-color: #161F3A;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            margin-right: 15px;
            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }
        &-text {
            font-size: 27px;
            font-weight: 700;
            max-width: calc(100% - 60px);
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto 30px;
        max-width: 770px;
        .photovoltaics-left--sm {
            width: 100%;
            display: block;
            @media(min-width: 1025px) {
                display: none;
            }
        }
        .photovoltaics-right--md {
            width: 100%;
            display: none;
            @media(min-width: 1025px) {
                display: block;
            }
        }
        .photovoltaics {
            order: 1;
            margin: 0;
            @media(min-width: 1025px) {
                order: 2;
            }
        }
        .photovoltaics__articles {
            order: 2;
            display: flex;
            flex-wrap: wrap;
            background-color: #161F3A;
            border-top: 1px solid #3A4259;
            padding: 35px 0 40px;
            @media(min-width: 576px) {
                padding: 35px 20px 40px;
            }
            @media(min-width: 1025px) {
                border-bottom: 1px solid #3A4259;
                padding: 35px 35px 40px;
                order: 1;
            }
        }
        .photovoltaics__article {
            padding: 0 15px;
            width: 100%;
            @media(min-width: 576px) {
                width: 50%;
            }
            @media(min-width: 1025px) {
                padding: 0;
                width: 100%;
            }
        }
    }

    &__article {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        &-image {
            margin-bottom: 16px;
        }
        &-title {
            font-size: 18px;
            font-weight: 400;
            color: $color-white;
            letter-spacing: -0.021em;
            line-height: 21px;
        }
    }
    &:hover {
        color: $color-white;
    }
    &-wide {
        max-width: 1170px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        &--sm {
            padding: 17px 45px;
            background-position: 280px 20px;
            margin: 15px auto 0;
            @media(min-width: 820px) {
                background-position: 640px 20px;
            }
            .photovoltaics__text {
                margin-left: 60px;
            }
        }
        &--md {
            padding: 30px 40px;
            background-position: 999999px 999999px;
            position: relative;
            @media(min-width: 820px) {
                background-position: 640px 48px;
            }
            .photovoltaics__text {
                margin-left: 90px;
            }
        }
        .photovoltaics__text {
            display: none;
            @media(min-width: 820px) {
                display: block;
            }
        }
    }
    &-left {
        max-width: 770px;
        &--sm {
            background-position: 300px 11px;
            padding: 17px 30px;
            @media(min-width: 576px) {
                padding: 17px 45px;
            }
        }
        &--md {
            padding: 20px 30px 36px;
            background-position: 999999px 999999px;
            @media(min-width: 440px) {
                background-position: 180px 41px;
            }
            @media(min-width: 576px) {
                background-position: 300px 41px;
                padding: 20px 30px 36px 61px;
            }
            .photovoltaics__logo {
                margin-bottom: 11px;
                margin-left: auto;
                margin-right: auto;
                @media(min-width: 440px) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .photovoltaics__title {
                text-align: center;
                @media(min-width: 440px) {
                    padding-left: 40px;
                    text-align: left;
                }
                img {
                    margin-left: 13px;
                    display: none;
                    @media(min-width: 576px) {
                        display: inline-block;
                    }
                }
            }
        }
        &--lg {
            padding: 20px 30px 36px;
            background-position: 999999px 999999px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            br {
                display: none;
                @media(min-width: 370px) {
                    display: inline;
                }
            }
            @media(min-width: 440px) {
                display: block;
                background-position: 290px -65px;
            }
            @media(min-width: 576px) {
                background-position: 430px -65px;
                padding: 50px 30px 60px 53px;
            }
            .photovoltaics__logo {
                margin-bottom: 24px;
            }

            .photovoltaics__title,
            .photovoltaics__text {
                @media(min-width: 440px) {
                    padding-left: 40px;
                }
            }
            .photovoltaics__title {
                text-align: center;
                @media(min-width: 440px) {
                    text-align: left;
                }
                img {
                    margin-left: 13px;
                    display: none;
                    @media(min-width: 576px) {
                        display: inline-block;
                    }
                }
            }
            .photovoltaics__text {
                margin-bottom: 50px;
            }
        }
    }
    &-right {
        max-width: 370px;
        br {
            display: none;
            @media(min-width: 370px) {
                display: inline;
            }
        }
        &--sm {
            background-position: 215px -250px;
            padding: 20px 20px 30px;
        }
        &--md {
            padding: 40px 20px 73px;
            background-position: 205px;
            .photovoltaics__logo {
                margin-bottom: 35px;
                margin-left: auto;
                margin-right: auto;
                @media(min-width: 440px) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .photovoltaics__title {
                text-align: center;
                @media(min-width: 440px) {
                    padding-left: 40px;
                    text-align: left;
                }
            }
        }
        &--lg {
            padding: 40px 30px 64px;
            background-position: 177px -75px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media(min-width: 440px) {
                display: block;
            }
            .photovoltaics__logo {
                margin-bottom: 35px;
                margin-left: auto;
                margin-right: auto;
                @media(min-width: 440px) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .photovoltaics__title,
            .photovoltaics__text {
                @media(min-width: 440px) {
                    padding-left: 30px;
                }
            }
            .photovoltaics__text {
                margin-bottom: 50px;
            }
        }
    }
    &__logo {
        display: block;
        max-width: 100%;
        height: auto;
    }
    &__title {
        font-size: 16px;
        font-weight: 700;
        text-decoration: underline;
        text-transform: uppercase;
        color: $color-white;
    }
    &__text {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;
    }
    &-swiper {
        width: 100%;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        &.swiper-container {
            margin-top: 30px;
        }
        &__next,
        &__prev {
            position: absolute;
            top: auto;
            bottom: 30px;
            right: 0;
            left: 0;
            padding: 0 15px;
            height: 45px;
            width: 75px;
            transition: opacity .2s ease-in-out;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            opacity: 0;
            cursor: pointer;
            z-index: 9;
            img {
                position: relative;
                z-index: 9;
            }
        }
        &__prev {
            right: auto;
            justify-content: flex-start;
            background:-moz-linear-gradient(90deg, rgba(22, 31, 58, 1) 0%, rgba(22, 31, 58, 0) 100%);
            background:-webkit-linear-gradient(90deg, rgba(22, 31, 58, 1) 0%, rgba(22, 31, 58, 0) 100%);
            background:-o-linear-gradient(90deg, rgba(22, 31, 58, 1) 0%, rgba(22, 31, 58, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#161F3A', endColorstr='#161F3A', GradientType=0 );
            background:-ms-linear-gradient(90deg, rgba(22, 31, 58, 1) 0%, rgba(22, 31, 58, 0) 100%);
            background:linear-gradient(90deg, rgba(22, 31, 58, 1) 0%, rgba(22, 31, 58, 0) 100%);
            img {
                transform: rotate(180deg);
            }
        }
        &__next {
            left: auto;
            justify-content: flex-end;
            &:before {
                content: "";
                top: 0;
                right: 0;
                width: 25px;
                height: 45px;
                background-color: #161F3A;
                position: absolute;
            }
            &:after {
                content: "";
                top: 0;
                right: 25px;
                width: 50px;
                height: 45px;
                position: absolute;
                background:-moz-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgba(22, 31, 58, 1) 100%);
                background:-webkit-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgba(22, 31, 58, 1) 100%);
                background:-o-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgba(22, 31, 58, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#161F3A', endColorstr='#161F3A', GradientType=0 );
                background:-ms-linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgba(22, 31, 58, 1) 100%);
                background:linear-gradient(90deg, rgba(22, 31, 58, 0) 0%, rgba(22, 31, 58, 1) 100%);
            }
        }
    }
    &__menu-item {
        display: inline-flex;
        padding: 0 20px;
        min-height: 45px;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        width: auto;
        border: 2px solid #454C61;
        border-radius: 3px;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        transition: background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out;
        white-space: nowrap;
        color: $color-white;
        &, &:link {
            color: $color-white;
        }
        &--active {
            background-color: $color-white;
            color: #161F3A;
            border-color: $color-white;
            &:link, &:visited {
                color: #161F3A;
            }
        }
        &:hover {
            color: $color-white;
            background-color: #454C61;
            border-color: $color-white;
        }
    }
}
.active {
    .photovoltaics-swiper {
        &, &__next, &__prev {
            opacity: 1;
            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}
