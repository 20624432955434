$color-font-main: #333;
$color-white: rgba(255,255,255,1);
$color-black: rgba(0,0,0,1);
$color-black-light: #333;
$color-gray-dark: #ababab;
$color-gray: #E1E1E1;
$color-gray-2: #d4d4d5;
$color-gray-light: #F0F0F0;
$color-gray-light-2: #95989A;
$color-blue-light: #0167B2;
$color-blue: #025592;
$color-blue-dark: #025592;
$color-blue-dark-2: #80a5c1;
$color-red: #FC4245;
$color-green:  #12C88A;
$color-orange:  #FBAB0C;
$color-gray-dark-2: #7E7E7E;
$color-calendar-border: rgba(204,204,204, .35);
$color-article-images: #D9D9D9;

$breakpoint-extra-mobile-max:     575.98px;
$breakpoint-mobile-min:           576px;
$breakpoint-mobile-max:           767.98px;
$breakpoint-tablet-min:           768px;
$breakpoint-tablet-max:           1024.98px;
$breakpoint-desktop-min:          1025px;
$breakpoint-desktop-max:          1199.98px;
$breakpoint-desktop-large-min:    1200px;

@font-face {
    font-family: 'Montserrat';
    src: url('https://cdn.avt.pl/fonts/MontserratRegular.eot');
    src: url('https://cdn.avt.pl/fonts/MontserratRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/MontserratRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/MontserratRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/MontserratRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/MontserratRegular.svg#MontserratRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('https://cdn.avt.pl/fonts/MontserratMedium.eot');
    src: url('https://cdn.avt.pl/fonts/MontserratMedium.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/MontserratMedium.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/MontserratMedium.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/MontserratMedium.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/MontserratMedium.svg#MontserratRegular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('https://cdn.avt.pl/fonts/MontserratSemiBold.eot');
    src: url('https://cdn.avt.pl/fonts/MontserratSemiBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/MontserratSemiBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/MontserratSemiBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/MontserratSemiBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/MontserratSemiBold.svg#MontserratBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('https://cdn.avt.pl/fonts/MontserratBold.eot');
    src: url('https://cdn.avt.pl/fonts/MontserratBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/MontserratBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/MontserratBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/MontserratBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/MontserratBold.svg#MontserratBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('https://cdn.avt.pl/fonts/MontserratBlack.eot');
    src: url('https://cdn.avt.pl/fonts/MontserratBlack.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/MontserratBlack.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/MontserratBlack.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/MontserratBlack.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/MontserratBlack.svg#MontserratBlack') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &-link-facebook::before { content: "\f082"; }
  &-link-youtube::before  { content: "\f167"; }
  &-link-linkedin::before { content: "\f08c"; }
  &-link-twitter::before  { content: "\f081"; }
  &-link-twitter::before  { content: "\f081"; }


}

[class^="fa-link"] {
    @extend .fa;
}

.fa-instagram:before          { content: "\f16d"; }
.fa-facebook-f:before,
.fa-facebook:before           { content: "\f09a"; }
.fa-facebook-square:before    { content: "\f082"; }
.fa-youtube:before            { content: "\f167"; }
.fa-search:before             { content: "\f002"; }
.fa-clock:before              { content: "\f017"; }
.fa-long-arrow-left:before    { content: "\f177"; }
.fa-long-arrow-right:before   { content: "\f178"; }
.fa-pinterest:before          { content: "\f0d2"; }
.fa-google-plus:before        { content: "\f0d5"; }
.fa-twitter:before            { content: "\f099"; }
.fa-twitter-square:before     { content: "\f081"; }
.fa-envelope:before           { content: "\f0e0"; }
.fa-chevron-right:before      { content: "\f054"; }
.fa-chevron-left:before       { content: "\f053"; }
.fa-exclamation-circle:before { content: "\f06a"; }
.fa-external-link:before      { content: "\f08e"; }
.fa-linkedin-square:before    { content: "\f08c"; }
.fa-user:before               { content: "\f007"; }
.fa-shopping-basket:before    { content: "\f291"; }
.fa-paper-plane:before        { content: "\f1d8"; }
.fa-share-square:before       { content: "\f14d"; }
.fa-file-word-o:before        { content: "\f1c2"; }
.fa-times-circle:before       { content: "\f057"; }
.fa-link-google-plus:before   { content: "\f0d4"; }
.fa-arrow-up:before           { content: "\f062"; }


body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
}

.alert-message {
    .close {
        display: none;
    }
    .alert-heading {
        display: none;
    }
}
.container-fluid {
    &.w {
        max-width: 1200px;
    }
}
.bg-white {
    background-color: $color-white;
}
.font-bold {
    font-weight: bold;
}

#system-message-container {
    @extend .container-fluid;
    max-width: 1200px;
    margin:  0 auto;
}

a, a:link, a:hover{
    color: $color-font-main;
    text-decoration: none;
}

.hidden-xl-down {
    overflow: hidden;
}

aside{
    section{
        &:first-child{
            .section-header{
                margin-top: 0 !important;
            }
        }
    }
}

video {
    max-width: 100%;
}
.img-aligner {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

%img-middle-wrapper {
    text-align: center;
    white-space: nowrap;
}

.float {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9001;
}

.position-relative { position: relative !important; }

// Fix for issues with elements within given tag
pre.language-php { white-space: normal; }

.bg-main { background-color: $color-white; }

.ad-img-responsive {
    img {
        max-width: 100%;
        height: auto;
    }
}
@mixin advertisementBg() {
    background: -moz-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.2))); /* safari4+,chrome */
    background: -webkit-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%); /* ie10+ */
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 ); /* ie6-9 */
}
@mixin articleBg() {
    background: -moz-linear-gradient(270deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.07) 28%, rgba(0,0,0,0.7) 100%); /* ff3.6+ */
   background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(0,0,0,0)), color-stop(28%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0.7))); /* safari4+,chrome */
   background: -webkit-linear-gradient(270deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.07) 28%, rgba(0,0,0,0.7) 100%); /* safari5.1+,chrome10+ */
   background: -o-linear-gradient(270deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.07) 28%, rgba(0,0,0,0.7) 100%); /* opera 11.10+ */
   background: -ms-linear-gradient(270deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.07) 28%, rgba(0,0,0,0.7) 100%); /* ie10+ */
   background: linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.07) 28%, rgba(0,0,0,0.7) 100%); /* w3c */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 ); /* ie6-9 */
}



.h-100 {
    height: 100%;
}

.p-sticky {
    top: 15px;
    position: sticky;
    transition: top .2s ease-in-out;
}

.menu-down {
    .p-sticky {
        top: 80px;
    }
}
.seo-article {
    background-color: #CEE1F0;
    position: relative;
    padding-bottom: 75px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: #ffffff;
        display: block;
        z-index: 1;
    }
    &__wrapper {
        background-color: $color-white;
        position: relative;
        z-index: 5;
        box-shadow: 10px 15px 46px #00000029;
        padding: 30px 15px 45px;
        @media(min-width: 768px) {
            padding: 30px 30px 60px;
        }
        @media(min-width: 1025px) {
            padding: 45px 45px 75px;
        }
        @media(min-width: 1200px) {
            padding: 60px 60px 90px;
        }
    }
    &__logo {
        max-width: 240px;
        margin: 0 auto 10px;
        @media(min-width: 576px) {
            margin: 0 0 10px;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 50px;
        text-align: center;
        @media(min-width: 576px) {
            text-align: left;
        }
    }
    &__introtext {
        font-size: 16px;
        line-height: 27px;
        font-weight: 500;
        a {
            text-decoration: none;
            color: #0967B0;
        }
    }
}
body,html {
    scroll-behavior: smooth;
}

body {
    &.search-open {
        position: fixed;
        overflow: hidden;
    }
}
#mobile-search-sticky {
    position: absolute;
    font-size: 24px;
    color: #989898;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: none;
}
.fixed {
    #mobile-search-sticky {
        display: flex;
    }
}

header {
    .socials {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-end;
        margin: 5px 0 30px;
        @media(min-width: 440px) {
            width: auto;
            justify-content: flex-start;
            margin: 0;
        }
        a {
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.mobile-search {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(-100%, 0, 0);
    position: fixed;
    background-image: url("/templates/main/images/search-bg.png");
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    background-position: center;
    @media(min-width: 768px) {
        background-size: cover;
    }
    &.open {
        opacity: 1;
        transform: translate3d(0,0,0);
        pointer-events: all;
    }
    &:after {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: #0967b0;
        opacity: 0.86;
    }
    &__socials {
        position: absolute;
        z-index: 3;
        top: 15px;
        left: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        a {
            margin-right: 25px;
            font-size: 30px;
            color: #ffffff;
            display: block;
            height: 30px;
            width: 30px;
            position: relative;
            &:last-child {
                margin-right: 0;
            }
            i {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        z-index: 3;
        cursor: pointer;
        &:before, &:after {
            content: "";
            height: 4px;
            width: 100%;
            position: absolute;
            background-color: $color-white;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &__form {
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 15px;
        right: 15px;
        height: 64px;
        margin: auto;
        max-width: 700px;
        box-shadow: 0 23px 18px rgba(0,0,0,0.26);
        input {
            height: 100%;
            width: 100%;
            margin: 0 auto;
            padding-left: 15px;
            padding-right: 70px;
            font-size: 14px;
            border: none;
            outline: none;
            &::placeholder {
                opacity: 1;
            }
        }
        button {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 64px;
            background: none;
            border: none;
            box-shadow: none;
            color: #999999;
            font-size: 24px;
            cursor: pointer;
        }
    }
}

#return-top {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    width: 60px;
    height: 60px;

    background-color: #0967b0;
    border: 3px solid #fff;
    border-radius: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 23px;
    transition: opacity .2s ease-in-out;
    z-index: 99999;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    @media(min-width: 501px) {
        bottom: 25px;
        right: 25px;
    }
    &.show {
        opacity: 1;
        pointer-events: all;
    }
}
