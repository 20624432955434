@import 'includes/options';
@import "includes/photovoltaics";

$color-bg-layer: rgba($color: #000, $alpha: 0.5);
$color-hover: lighten($color: $color-blue, $amount: 20%);

html, body { height: 100%; }
body       { margin: 0; }

::selection { background: rgba($color: $color-blue, $alpha: 0.75); }

.error {
    &-header {
        height: 100px;
        padding: 32px 15px;
        background-color: #fff;

        .logo {
            position: absolute;
            top: 50%;

            display: block;
            width: 100%;

            transform: translateY(-50%);

            &-box {
                position: relative;
                display: block;
                height: 100%;
                max-width: 309px;
                margin: 0 auto;
            }
        }
    }

    &-main {
        width: 100%;
        height: calc(100vh - 100px);

        background-image: linear-gradient($color-bg-layer, $color-bg-layer), url('../images/background-error.jpg');
        background-repeat: repeat-y, no-repeat;
        background-size: cover, cover;
        background-position: center, center top;

        overflow-x: hidden;
        overflow-y: auto;

        .content-wrapper {
            margin: 0 auto;
            padding: 8vh 15px;
            color: #fff;
            text-align: center;

            @media (min-width: $breakpoint-mobile-min) { padding: 10vh 15px; }
            @media (min-width: 1366px)                 { padding: 12vh 15px; }

            a {
                color: #fff;
                font-weight: 700;
                transition: color .25s ease-in;

                &:hover { color: $color-hover; }
            }
        }
    }

    &-message,
    &-hint {
        font-size: 18px;
        font-weight: 500;

        @media (min-width: $breakpoint-mobile-min)        { font-size: 25px; }
        @media (min-width: 1366px)                        { font-size: 30px; }
    }

    &-hint { margin-bottom: 30px; }

    &-code {
        font-size: 100px;
        font-weight: 700;
        line-height: 1.2;

        @media (min-width: $breakpoint-mobile-min)        { font-size: 200px; }
        @media (min-width: $breakpoint-desktop-large-min) { font-size: 210px; }
        @media (min-width: 1366px)                        { font-size: 240px; }
    }
}

.search {

    &-input,
    &-button {
        display: block;
        margin: 0 auto;

        color: #fff;
        border: none;
        background: none;
    }

    &-input {
        @extend .error-hint;
        margin-bottom: 20px;
        border-bottom: 4px solid rgba($color: #fff, $alpha: 0.5);
        text-align: center;
        transition: border-bottom-color .25s ease-in;

        @media (min-width: $breakpoint-mobile-min)        { margin-bottom: 30px; }
        @media (min-width: $breakpoint-tablet-min)        { margin-bottom: 30px; }
        @media (min-width: $breakpoint-desktop-min)       { margin-bottom: 30px; }
        @media (min-width: $breakpoint-desktop-large-min) { margin-bottom: 30px; }

        &:focus { border-bottom-color:  rgba($color: #fff, $alpha: 1); }
    }

    &-button {
        padding: 12px 40px;
        background-color: $color-blue;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        transition: background-color .25s ease-in;

        @media (min-width: $breakpoint-mobile-min) {
            padding: 14px 50px;
            font-size: 16px;
        }

        @media (min-width: $breakpoint-tablet-min) {
            padding: 14px 50px;
            font-size: 16px;
        }

        @media (min-width: $breakpoint-desktop-min) {
            padding: 14px 50px;
            font-size: 16px;
        }

        @media (min-width: $breakpoint-desktop-large-min) {
            padding: 14px 50px;
            font-size: 16px;
        }

        @media (min-width: 1366px) {
            padding: 18px 60px;
            font-size: 20px;
        }

        &:hover,
        &:focus {
            cursor: pointer;
            background-color: $color-hover;
        }
    }
}

.debug {
    margin-top: 30px;
    text-align: left;
    overflow-x: auto;

    .table {
        margin: 0 auto;

        td       { padding: 2px 5px; }
        tr:hover { background: rgba($color: #fff, $alpha: 0.25); }
    }

    &-error {
        @extend .error-message;
        text-align: center;

        &-code { font-weight: 700; }
    }
}
